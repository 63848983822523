import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { css } from "aphrodite"
import { button_styles, label_styles, table_styles } from "../../styles/reusable_styles"
import { deviceInterface, deviceListEntryInterface } from "../../../models/interfaces/device_interfaces"
import { device_controller } from "../../../controllers/device_controller"
import { alphanumeric_comparator } from "../../../utils/helper_functions"
import Typography from "@mui/material/Typography"
import { useTranslation } from "../../../contexts/TranslationContext"
import { useRecoilValue } from "recoil"
import { showClientIdentifierSelector } from "../../../models/atoms/device_atoms"

export const DeviceEntry = (props: { device: deviceListEntryInterface, selectedDevice: deviceInterface | null, alertLabels: string[]}) => {
	const device = props.device;
	const selectedDevice = props.selectedDevice;
	const alertLabels = props.alertLabels;
	const showClientIdentifier = useRecoilValue(showClientIdentifierSelector);
	const { t } = useTranslation();

	return (<TableRow key={device.device_id} onClick={() => {
		if (!selectedDevice || selectedDevice.device_id !== device.device_id) {
			device_controller.select_device(device.device_id)
		}
	}}>
		<TableCell align='center' width="20%" className={css(device.device_id === selectedDevice?.device_id ? table_styles.table_cell_selected : table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{device.device_id}</Typography></TableCell>
		{showClientIdentifier
			? <TableCell align='center' width="20%" className={css(device.device_id === selectedDevice?.device_id ? table_styles.table_cell_selected : table_styles.table_cell)}>
				<Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>
					{device.client_identifier}
				</Typography>
			</TableCell>
			: null}
		<TableCell align='center' width="25%" className={css(device.device_id === selectedDevice?.device_id ? table_styles.table_cell_selected : table_styles.table_cell)}><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{device.current_level_percentage}</Typography></TableCell>
		<TableCell align='center' width="30%" className={css(device.device_id === selectedDevice?.device_id ? table_styles.table_cell_selected : table_styles.table_cell)}>
		<div className={css(label_styles.labels_container)}>
		{ device.labels.slice().sort(alphanumeric_comparator).map((label) => {
			if (label === "Low battery" || label === "No messages" || alertLabels.includes(label)) {
				if (device.labels.includes(label)) {
				return <div className={css(label_styles.label_red)} key={label}>
					<Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{t(label)}</Typography>
				</div>
				}
			}
			return <div className={css(label_styles.label)} key={label}>
				<Typography sx={{fontWeight: 600, fontfamily: '"Ubuntu", sans-serif'}}>{t(label)}</Typography>
			</div>
			})
		}
		</div>
		</TableCell>
		<TableCell align='center' width="25%" className={css(device.device_id === selectedDevice?.device_id ? table_styles.table_cell_selected : table_styles.table_cell)}>
		<button className={device.pinned ? css(button_styles.main_button) : css(button_styles.main_button_disabled)} 
			style={{flex: 1, alignItems: 'center', justifyContent: 'center', padding: '0.1rem 0.3rem', minWidth: '5rem' }}
					onClick={() => { device_controller.toggle_device_pin(device.device_id); }}
		>
			<Typography sx={{fontWeight: 600, fontfamily: '"ubuntu", sans-serif'}}>{device.pinned ? t("Y") : t("N")}</Typography>
		</button>
		</TableCell>
	</TableRow>)
}
