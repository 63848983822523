import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import isEqual from 'lodash/isEqual';
import { css } from "aphrodite";
import { Checkbox, MenuItem, OutlinedInput, Select, type SelectChangeEvent, ThemeProvider } from "@mui/material";
import { theme } from "../Theme";
import { button_styles } from "../../styles/reusable_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { defaultFilterState, filterStateAtom, selectedDeviceStateAtom, systemTypesSelector } from "../../../models/atoms/device_atoms";
import { alphanumeric_comparator } from "../../../utils/helper_functions";
import { labelsAtom } from "../../../models/atoms/company_atoms";
import { ALERT_LABELS } from "./DeviceList";
import { filterStateInterface } from "../../../models/interfaces/device_interfaces";
import { device_controller } from "../../../controllers/device_controller";

const DEFAULT_FILTERS = {
    pinned: 'Pinned',
    lowBattery: 'Low battery',
    noMessages: 'No messages',
    highROC: 'High ROC',
    blockage: 'Blockage',
    highWarningLevel: 'High Level',
};

export function DevicesFilter() {
    const { t } = useTranslation();
    const systemTypes = useRecoilValue(systemTypesSelector);
    const { labels } = useRecoilValue(labelsAtom);
    const [filterState, setFilterState] = useRecoilState(filterStateAtom);
    const resetFilter = useResetRecoilState(filterStateAtom);
    const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const additionalFilters = [
        ...systemTypes.slice().sort(alphanumeric_comparator),
        ...labels
            .filter(label => !systemTypes.includes(label) && !ALERT_LABELS.includes(label))
            .sort(alphanumeric_comparator)
    ];

    function handleChange({ target: { value } }: SelectChangeEvent<string[]>) {
        setSelectedFilters(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    function toggleIsOpen() {
        setIsOpen(o => !o);
    }

    async function updateFilters() {
        setIsOpen(false);
        const newFilterState = selectedFilters.reduce<filterStateInterface>(
            (acc, filter) => {
                if (filter === 'pinned' ||
                    filter === 'lowBattery' ||
                    filter === 'noMessages' ||
                    filter === 'highROC' ||
                    filter === 'blockage' ||
                    filter === 'highWarningLevel'
                ) {
                    acc[filter] = true;
                } else {
                    acc.labels.push(filter);
                }
                return acc;
            },
            {
                ...defaultFilterState,
                searchText: filterState.searchText,
                labels: [],
            },
        );

        if (isEqual(
            { ...newFilterState, labels: new Set(newFilterState.labels) },
            { ...filterState, labels: new Set(filterState.labels) },
        )) {
            return;
        }

        setFilterState(newFilterState);
        await device_controller.get_device_list();
    }

    async function handleResetFilter() {
        resetFilter();
        await device_controller.get_device_list();
        setSelectedDevice(null);
    }

    useEffect(() => {
        setSelectedFilters(
            Object.entries(filterState).reduce<string[]>(
                (acc, [key, value]) => {
                    if (key === 'searchText') {
                        return acc;
                    }

                    if (key === 'labels') {
                        acc.push(...value);
                    } else if (value) {
                        acc.push(key);
                    }

                    return acc;
                },
                [],
            ),
        );
    }, [filterState]);

    return (
        <ThemeProvider theme={theme}>
            <div style={{ position: 'relative' }}>
                <button
                    className={css(button_styles.main_button)}
                    style={{ width: '8rem', margin: '0 10px !important', padding: '0.4rem 0.7rem', marginRight: '0.6rem' }}
                    onClick={toggleIsOpen}
                >
                    <i className="bi-filter" />
                    {t("Filter")}
                </button>
                <Select
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    multiple
                    value={selectedFilters}
                    onChange={handleChange}
                    input={
                        // hidden input to make the select component work
                        <OutlinedInput
                            style={{ width: '100%', height: 0, position: 'absolute', left: 0, bottom: 0, opacity: 0 }}
                        />
                    }
                    renderValue={() => null}
                    onClose={updateFilters}
                >
                    {Object.entries(DEFAULT_FILTERS).map(([key, label]) => (
                        <MenuItem
                            key={key}
                            value={key}
                        >
                            <Checkbox
                                checked={selectedFilters.includes(key)}
                                sx={{ py: 0.5 }}
                            />
                            {t(label)}
                        </MenuItem>
                    ))}
                    {additionalFilters.map(filter => (
                        <MenuItem
                            key={filter}
                            value={filter}
                        >
                            <Checkbox
                                checked={selectedFilters.includes(filter)}
                                sx={{ py: 0.5 }}
                            />
                            {t(filter)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <button
                className={css(button_styles.main_button)}
                style={{ width: '8rem', margin: '0rem 0.5rem !important', padding: '0.4rem 0.7rem' }}
                onClick={handleResetFilter}
            >
                {t("Clear Filter")}
            </button>
        </ThemeProvider>
    )
}
