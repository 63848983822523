import {
	atom, selector,
} from 'recoil';

import { deviceListInterface, deviceInterface, filterStateInterface, deviceDataInterface } from "../interfaces/device_interfaces";
import dayjs, { Dayjs } from 'dayjs';

export const deviceListStateAtom = atom<deviceListInterface>({
	key: 'deviceListState', // unique ID (with respect to other atoms/selectors)
	default: {
		devices: [],
		search_filter: {
			latitude: null,
			longitude: null,
			distance: null,
			warning_level: null,
			start_index: null,
			end_index: null,
			labels: null,
			pinned: null
		},
		has_client_identifier: false
	}, // default value (aka initial value)
});

export const systemTypesAtom = atom({
	key: 'systemTypesAtom',
	default: new Set<string>(),
});

export const systemTypesSelector = selector({
	key: 'systemTypesSelector',
	get: ({ get }) => Array.from(get(systemTypesAtom)),
});

export const selectedDeviceStateAtom = atom<deviceInterface | null>({
	key: 'selectedDeviceState',
	default: null,
});

export const selectedDeviceDataAtom = atom<deviceDataInterface | null>({
	key: 'selectedDeviceData',
	default: null,
})

export const defaultFilterState = {
	pinned: false,
	lowBattery: false,
	highWarningLevel: false,
	noMessages: false,
	highROC: false,
	blockage: false,
	searchText: '',
	labels: [],
}
export const filterStateAtom = atom<filterStateInterface>({
	key: 'filterStateAtom',
	default: defaultFilterState,
})


export const filteredDeviceListSelector = selector({
	key: 'filteredDeviceListSelector',
	get: ({ get }) => {
		const filter = get(filterStateAtom);
		const deviceList = get(deviceListStateAtom);

		if (!filter.highWarningLevel && !filter.labels.length && !filter.lowBattery && !filter.pinned && !filter.searchText && !filter.noMessages && !filter.blockage) {
			return { devices: deviceList.devices }
		}

		return {
			devices: deviceList.devices.filter(device => {
				if (!(device.device_id.toLowerCase().includes(filter.searchText.toLowerCase()) || device.client_identifier?.toLowerCase().includes(filter.searchText.toLowerCase()))) {
					return false;
				} else if (filter.pinned && !device.pinned) {
					return false;
				} else if (filter.lowBattery && device.battery_percentage >= 25) {
					return false;
				} else if (filter.highWarningLevel && device.warning_level < 10) {
					return false;
				} else if (filter.highROC && device.labels.includes('High ROC')) {
					return false;
				} else if (filter.noMessages && !device.labels.includes('No messages')) {
					return false;
				} else if (filter.blockage && !device.labels.includes('blockage')) {
					return false;
				} else {
					return filter.labels.every(label => device.labels.includes(label));
				}
			})
		};
	},
})

export const requestIDAtom = atom<number>({
	key: 'requestIDAtom',
	default: 0
})

export const dateRangeAtom = atom<{ start: Dayjs, end: Dayjs }>({
	key: 'dateRangeAtom',
	default: {
		start: dayjs(0),
		end: dayjs(0)
	}
})

export const showClientIdentifierSelector = selector({
	key: 'showClientIdentifierSelector',
	get: ({ get }) => {
		const { has_client_identifier } = get(deviceListStateAtom);
		return has_client_identifier;
	}
});
