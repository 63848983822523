import { css } from "aphrodite";
import { button_styles } from "../../styles/reusable_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { ChangeEvent, useRef, useState } from "react";
import { device_controller } from "../../../controllers/device_controller";

export function DeviceImageUpload({ deviceID }: { deviceID: string }) {
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [images, setImages] = useState<File[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const isButtonDisabled = Boolean(error) || images.length === 0;

    function validateImages(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            // e.target.files should never be null for file input
            // this is just to satisfy TypeScript type checking
            return;
        }

        if (e.target.files.length > 5) {
            setError('You can only upload up to 5 photos at a time');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
            return;
        }

        const newImages = Array.from(e.target.files);
        const hasInvalidImageType = newImages.some(
            ({ type }) => !type.startsWith('image/'),
        );
        if (hasInvalidImageType) {
            setError('Invalid photos selected');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
            return;
        }

        setImages(newImages);
        setError('');
    }

    async function uploadImages() {
        try {
            await device_controller.uploadImage(deviceID, images);
        } catch (e) {
            console.error(e);
            if (!(e instanceof Error) || !e.cause) {
                setError('Failed to upload photos. Please try again later');
                return;
            }

            setError(String(e.cause));
            return;
        }

        setImages([]);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        device_controller.select_device(deviceID);
    }

    return (
        <div>
            <p className={css(deviceInfoPanelStyles.subsection_text)}>
                <span style={{ fontWeight: 500 }}>
                    {t('Upload New Photos')}
                </span>
            </p>
            <p className={css(deviceInfoPanelStyles.disclaimer)}>
                {t('Select up to 5 photos to upload')}
            </p>
            <input
                ref={inputRef}
                accept="image/*"
                type="file"
                multiple
                style={{ display: 'block' }}
                onChange={validateImages}
            />
            {error
                ? <p className={css(deviceInfoPanelStyles.error_text)}>{t(error)}</p>
                : null}
            <button
                className={css(isButtonDisabled ? button_styles.main_button_disabled : button_styles.main_button)}
                disabled={isButtonDisabled}
                style={{ padding: '0.5rem 1rem', margin: '0.3rem 0' }}
                onClick={uploadImages}
            >
                {t('Upload')}
            </button>
        </div>
    );
}
