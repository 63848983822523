import { css } from "aphrodite";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart } from 'recharts';
import { deviceDataInterface } from "../../../models/interfaces/device_interfaces";

const formatDate = (date: number, language: string, date_format: Intl.DateTimeFormatOptions) => {
	const numericDate = new Date(date * 1000);
	return numericDate.toLocaleDateString(language, date_format);
};

const renderTooltip = (show_water_level: boolean, show_rainfall_level: boolean, max_height: number) => (props: any) => {
	const { t, language, distance } = useTranslation();
	if (props.payload.length === 0 || (props.payload[0].payload.fake_water_level && props.payload[0].payload.fake_rainfall_level) || (props.payload[0].payload.fake_water_level && !show_rainfall_level) || (props.payload[0].payload.fake_rainfall_level && !show_water_level)) {
		return <></>
	}

	return <div style={{ backgroundColor: "white", padding: '4px', borderColor: '#000099', border: 'ridge', borderWidth: '1' }}>
		<p>{formatDate(props.label, language, { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}</p>
		{show_water_level && props.payload[0].payload.water_level !== undefined && <p style={{ color: '#000099' }}>{t('Water Level:')} {distance.show(distance.to_metric(props.payload[0].payload.water_level))}</p>}
		{show_water_level && props.payload[0].payload.water_level !== undefined && <p style={{ color: '#000099' }}>{t('Fill:')} {(distance.to_metric(props.payload[0].payload.water_level) / max_height * 100).toFixed(1)}%</p>}
		{show_rainfall_level && props.payload[0].payload.rainfall_level !== undefined && <p style={{ color: '#009900' }}>{t('Rainfall Level:')} {distance.show(distance.to_metric(props.payload[0].payload.rainfall_level))}</p>}
	</div>
}

const getTicks = (data?: { times?: number[] }) => {
	if (!data || !data.times) {
		return [];
	} else {
		return [data.times[0], data.times[data.times.length - 1]];
	}
}

export type DeviceData = {
	water_data: { water_level: number, time: number }[],
	rain_data: { rainfall_level: number, time: number }[],
}

export type DeviceDataFunction = (data: DeviceData) => DeviceData

export type DeviceGraphProps = {
	title?: string,
	data: deviceDataInterface,
	show_water_level: boolean,
	show_rainfall: boolean,
	max_height: number,
	pipeline: DeviceDataFunction[],
}

export const high_low_filter = (low_threshold: number, high_threshold: number) => (data: DeviceData): DeviceData => {
	return { rain_data: data.rain_data, water_data: data.water_data.filter(x => x.water_level >= low_threshold && x.water_level <= high_threshold) };
}

export const DeviceGraph = (props: DeviceGraphProps) => {
	const { t, language, distance } = useTranslation();
	if (!(props.show_water_level || props.show_rainfall)) {
		return <></>
	}

	let water_data = [];
	let rain_data = [];
	for (let i = 0; i < props.data.times.length; i++) {
		if (props.data.water_levels[i] >= 0) {
			water_data.push({
				time: props.data.times[i],
				water_level: distance.from_metric(props.data.water_levels[i]),
			});
		}
		if (props.data.rainfall_levels[i] >= 0) {
			rain_data.push({
				time: props.data.times[i],
				rainfall_level: distance.from_metric(props.data.rainfall_levels[i]),
			});
		}
	}


	let data = props.pipeline.reduce((data: DeviceData, func: DeviceDataFunction) => func(data), { water_data, rain_data });
	water_data = data.water_data;
	rain_data = data.rain_data;

	return (<div>
		{props.title && <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t(props.title)}</span></p>}
		<ResponsiveContainer width={'100%'} height={300} style={{ paddingRight: props.show_rainfall && props.show_water_level ? 0 : 70 }}>
			<LineChart height={300} className={css(deviceInfoPanelStyles.chart)}>
				<CartesianGrid stroke="#ccc" />
				<Tooltip
					isAnimationActive={false}
					content={renderTooltip(props.show_water_level, props.show_rainfall, props.max_height)}
				/>
				<XAxis xAxisId="time" dataKey="time" tickFormatter={(date) => formatDate(date, language, { day: 'numeric', month: 'short', year: 'numeric' })} ticks={getTicks(props.data)} type="number" scale="time" domain={['auto', 'auto']} />
				{props.show_water_level && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="water" xAxisId="time" dataKey="water_level" stroke="rgb(0, 0, 153)" strokeWidth="3px" dot={false} data={water_data} />
					<YAxis yAxisId="water" dataKey="water_level" width={70} stroke="rgb(0, 0, 153)" domain={[0, distance.from_metric(props.max_height)]} scale='linear'></YAxis>
				</>}
				{props.show_rainfall && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="rainfall" xAxisId="time" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" strokeWidth="3px" dot={false} activeDot={!props.show_water_level} data={rain_data} />
					<YAxis yAxisId="rainfall" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" orientation={props.show_water_level ? "right" : "left"} width={70} domain={[0, "dataMax + 1"]} scale='linear'></YAxis>
				</>}
			</LineChart>
		</ResponsiveContainer>
	</div>);
}
