import '../../App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import { FleetMetrics } from './FleetMetrics';
import { css } from 'aphrodite';
import { map_styles } from '../styles/map_styles';
import { DevicesMap } from './device/DevicesMap';

export const MapComponent = () => {
	return <div data-testid="Map Component" className={css(map_styles.map_component)}>
		<DevicesMap />
		<FleetMetrics/>
	</div>;
}


