import { useRecoilValue } from "recoil";
import { css } from "aphrodite";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { maxRainfallLevelSelector, maxWaterLevelSelector } from "../../../models/selectors/device_selectors";

type Props = {
    showWaterLevel: boolean;
    showRainfallLevel: boolean;
}

export function DeviceDataRangeStats({ showWaterLevel, showRainfallLevel }: Props) {
    const { t, distance } = useTranslation();
    const maxWaterLevel = useRecoilValue(maxWaterLevelSelector);
    const maxRainfallLevel = useRecoilValue(maxRainfallLevelSelector);

    return (
        <div style={{ marginBottom: '1rem' }}>
            {showWaterLevel
                ? <p className={css(deviceInfoPanelStyles.subsection_text)}>
                    <span style={{ fontWeight: 500 }}>
                        {t('Max water level in selected range:')}&nbsp;
                    </span>
                    {maxWaterLevel ? distance.show(maxWaterLevel) : t('No data')}
                </p>
                : null}
            {showRainfallLevel
                ? <p className={css(deviceInfoPanelStyles.subsection_text)}>
                    <span style={{ fontWeight: 500 }}>
                        {t('Max rainfall level in selected range:')}&nbsp;
                    </span>
                    {maxRainfallLevel ? distance.show(maxRainfallLevel) : t('No data')}
                </p>
                : null}
        </div>
    );
}
