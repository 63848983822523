import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  RecoilRoot
} from 'recoil';
import RecoilNexus from "recoil-nexus";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

function Root() {
  const [recoilKey, setRecoilKey] = useState(0);

  function handleLogout() {
    // resets all states when user logged out
    // https://github.com/facebookexperimental/Recoil/issues/785
    setRecoilKey(k => k + 1);
  }

  return (
    <RecoilRoot key={recoilKey}>
      <RecoilNexus />
      <App onLogout={handleLogout} />
    </RecoilRoot>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
