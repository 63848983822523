import type { AtomEffect } from 'recoil';

/**
 * Recoil Atoms effect with LocalStorage persistence
 * @see https://recoiljs.org/docs/guides/atom-effects#local-storage-persistence
 */
export function atomLocalStorageEffect<T>(key: string): AtomEffect<T> {
    return ({ onSet, setSelf }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            if (isReset) {
                localStorage.removeItem(key);
                return;
            }

            localStorage.setItem(key, JSON.stringify(newValue));
        });
    };
}
