import { useEffect, useState } from "react";
import { Button, Drawer } from "@mui/material";
import { css } from "aphrodite";
import { appStyles, mobileBreakPoint } from "../../styles/app_styles";
import "bootstrap-icons/font/bootstrap-icons.css";
import { NavButton } from "./NavButton";
import { NavState } from "../../../App";
import { useTranslation } from "../../../contexts/TranslationContext";
// @ts-ignore
import worldIcon from '../../../assets/world-icon.png';
// @ts-ignore
import germany from '../../../assets/germany.png'
// @ts-ignore
import uk from '../../../assets/uk.png'
// @ts-ignore
import us from '../../../assets/us.png'

type Props = {
	navState: NavState;
	setNavState: (state: NavState) => void;
	showAccountPage: boolean;
	logout: () => void;
};

export function MobileNavMenu({ navState, setNavState, showAccountPage, logout }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [isLanguageOpen, setIsLanguageOpen] = useState(false);
	const { t, changeLanguage } = useTranslation();

	function toggleIsOpen() {
		setIsOpen(o => !o);
	}

	function closeDrawer() {
		setIsOpen(false);
	}

	function toggleIsLanguageOpen() {
		setIsLanguageOpen(l => !l);
	}

	useEffect(() => {
		function resizeCloseDrawer(e: UIEvent) {
			if (window.innerWidth <= mobileBreakPoint) {
				return;
			}

			closeDrawer();
		}

		window.addEventListener('resize', resizeCloseDrawer);
		return () => {
			window.removeEventListener('resize', resizeCloseDrawer);
		}
	}, []);

	return (<>
		<Button
			className={css(appStyles.nav_mobile_menu_button)}
			onClick={toggleIsOpen}
		>
			<i className="bi bi-list" />
		</Button>
		<Drawer
			open={isOpen}
			anchor="right"
			onClose={closeDrawer}
		>
			<div className={css(appStyles.nav_mobile_menu_content)}>
				<NavButton
					isActive={navState === 'device-metrics'}
					onClick={() => {
						setNavState('device-metrics');
						closeDrawer();
					}}
				>
					{t('Devices')}
				</NavButton>
				{
					showAccountPage
						? <NavButton
							isActive={navState === 'accounts-page'}
							onClick={() => {
								setNavState('accounts-page');
								closeDrawer();
							}}
						>
							{t('Accounts')}
						</NavButton>
						: null
				}
				<NavButton
					isActive={navState === 'settings-page'}
					onClick={() => {
						setNavState('settings-page');
						closeDrawer();
					}}
				>
					{t('Settings')}
				</NavButton>
				<NavButton
					isActive={false}
					onClick={logout}
				>
					{t('Logout')}
				</NavButton>
				<NavButton
					isActive={false}
					onClick={toggleIsLanguageOpen}
				>
					<img
						src={worldIcon}
						alt="World Icon"
						className={css(appStyles.language_icon)}
					/>
				</NavButton>
				<div className={css(appStyles.nav_mobile_language_dropdown, isLanguageOpen && appStyles.nav_mobile_language_dropdown_deactivated)}>
					<img
						src={germany}
						alt="German Flag"
						onClick={() => {
							changeLanguage('de');
							closeDrawer();
						}}
						style={{ cursor: 'pointer', width: '40px', height: '40px' }} // Adjust these values
					/>
					<img
						src={uk}
						alt="UK Flag"
						onClick={() => {
							changeLanguage('en-GB');
							closeDrawer();
						}}
						style={{ cursor: 'pointer', width: '40px', height: '40px', }} // Adjust these values
					/>
					<img
						src={us}
						alt="US Flag"
						onClick={() => {
							changeLanguage('en-US');
							closeDrawer();
						}}
						style={{ cursor: 'pointer', width: '40px', height: '40px', }} // Adjust these values
					/>
				</div>
			</div>
		</Drawer>
	</>);
}
