import {
	atom
} from 'recoil';

import { dashboardVersionInterface } from '../interfaces/ui_interfaces';
import { atomLocalStorageEffect } from '../../utils/atomLocalStorageEffect';
import { Language } from '../../contexts/TranslationContext';

export const panelSizesAtom = atom<{
	hTop: string,
	hBottom: string,
	vLeft: string,
	vRight: string
}>({
	key: 'panelSizes',
	default: {
		hTop: '0px',
		hBottom: '0px',
		vLeft: '0px',
		vRight: '0px'
	}
})


export const navStateAtom = atom<'device-metrics' | 'accounts-page' | 'settings-page' | 'battery-calculator'>({
	key: 'navState',
	default: 'device-metrics'
})

export const dashboardVersionAtom = atom<dashboardVersionInterface>({
	key: 'dashboardVersion',
	default: {
		version: '2.0.0'
	},
})

export const LANGUAGE_STORAGE_KEY = 'language';
export const languageAtom = atom<Language>({
	key: 'language',
	default: 'en-GB',
	effects: [atomLocalStorageEffect(LANGUAGE_STORAGE_KEY)],
})
