import React from 'react';
import { memo, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeCompanyDeviceAtom, changeWeathermodeAtom } from "../../../models/atoms/company_atoms";
import { company_controller } from "../../../controllers/company_controller";
import { toHour, toMinute } from "../../../utils/helper_functions";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { useTranslation } from '../../../contexts/TranslationContext';
import { usePermissionsModalContext } from '../../../contexts/PermissionModalContext';
import { canManageDevicesSelector } from '../../../models/selectors/permission_selectors';
import { MAX_MEASUREMENTS } from './DeviceSettings';
/* eslint-disable no-useless-escape */

export const WeatherModeSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t, distance } = useTranslation();
	const [times, setTimes] = useRecoilState(changeCompanyDeviceAtom);
	const [weathermodeSettings, setWeathermodeSettings] = useRecoilState(changeWeathermodeAtom);
	const [weathermodeRelayError, setWeathermodeRelayError] = useState('');
	const [weathermodeReadingError, setWeathermodeReadingError] = useState('');
	const [rainfallThresholdError, setRainfallThresholdError] = useState('');
	const [weathermodeTimesChanged, setWeathermodeTimesChanged] = useState(false);
	const [totalMeasurementsWarning, setTotalMeasurementsWarning] = useState('');
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const canManageDevices = useRecoilValue(canManageDevicesSelector);
	const { openPermissionsModal } = usePermissionsModalContext();
	function checkPermission() {
		if (!canManageDevices) {
			openPermissionsModal();
		}
	}

	useEffect(() => {
		if (!times) return;

		const totalWeathermodeMeasurements = times.weathermode_message_wait_time_s / times.weathermode_measurement_sleep_time_s;

		if (totalWeathermodeMeasurements > MAX_MEASUREMENTS) {
			setTotalMeasurementsWarning(t('Total measurements exceed 100! Devices are restricted to 100 measurents per transmission interval.', { maxMeasurements: MAX_MEASUREMENTS.toString() }));
		} else {
			setTotalMeasurementsWarning('');
		}
		if (times.weathermode_message_wait_time_s < 720 || times.weathermode_message_wait_time_s > (72 * 60 * 60)) {
			setWeathermodeRelayError(t('Not in the allowable range of 0.2-72 hours'));
		} else {
			setWeathermodeRelayError('');
		}
		const maxWaitTime = Math.min(720 * 60, times.weathermode_message_wait_time_s);
		if (times.weathermode_measurement_sleep_time_s < 300 || times.weathermode_measurement_sleep_time_s > maxWaitTime) {
			setWeathermodeReadingError(t('Not in the allowable range minutes', { maxReadingFrequency: (maxWaitTime / 60).toFixed(1) }));
		} else {
			setWeathermodeReadingError('');
		}
	}, [times, t]);

	if (!times || !weathermodeSettings) {
		return <></>
	}

	const isSaveButtonEnabled = canManageDevices && (weathermodeSettings?.change_weathermode || weathermodeTimesChanged) && !weathermodeRelayError && !weathermodeReadingError && !rainfallThresholdError && !totalMeasurementsWarning;

	return <form id='weather_mode' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
		e.preventDefault();
		if (weathermodeSettings == null) { return; }
		company_controller.modify_company_weathermode_settings(weathermodeSettings?.weathermode_on, weathermodeSettings?.rainfall_level_to_warn_mm).then((resp) => {
			company_controller.modify_device_settings(null, null, null, null, times?.weathermode_message_wait_time_s, times?.weathermode_measurement_sleep_time_s);
		})
	}}>

		<h2 className={css(SettingsPageStyles.card_title)}>{t('Weather Mode')}</h2>
		<div className={css(SettingsPageStyles.settings_card_body)}>
			<div style={{ display: "flex", width: '10rem', justifyContent: 'space-between' }}>
				<label className="switch">
					<input
						type="checkbox"
						checked={weathermodeSettings === null ? false : weathermodeSettings.weathermode_on}
						disabled={!canManageDevices}
						onChange={async (e) => {
							if (weathermodeSettings === null) { return; }
							setWeathermodeSettings({
								...weathermodeSettings,
								weathermode_on: e.target.checked,
								change_weathermode: true
							});
						}}
					/>
					<span
						className={`slider round ${css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}`}
						onClick={checkPermission}
					/>
				</label>
				<p style={{ fontWeight: 500 }}>{t('Weather Mode')}</p>
			</div>
			<p className={css(SettingsPageStyles.disclaimer)}>
				{t("Weather Mode monitors forecast rainfall in each device's location. Each time a device relays data, the system checks the forecasted hourly rainfall for the next 24 hours with the maximum hourly threshold configured below. If the maximum hourly rainfall for the next 24 hours exceeds the threshold, the device frequencies are updated to those defined below, until the maximum hourly rainfall for the next 24 hours is checked (each time the device relays data) and is found to fall below the threshold. These settings ensure that not only battery life is conserved, as less frequent Normal Operating frequencies can be selected, but that there is also sufficient flow of data during high risk rainfall events to provide network insights.")}
			</p>
			{weathermodeSettings?.weathermode_on &&
				<section className={css({ ...SettingsPageStyles.settings_card_sections_container, width: '100% !important', marginBottom: 0 })} style={{ width: '100%', marginTop: '2rem' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<label className={css(SettingsPageStyles.card_input_label)}>{t('Rainfall Threshold (per hour):')}</label>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span
								className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
								onClick={checkPermission}
							>
								<input
									className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
									type="text"
									defaultValue={distance.from_metric(weathermodeSettings?.rainfall_level_to_warn_mm).toFixed(1)}
									disabled={!canManageDevices}
									onChange={(e) => {
										setRainfallThresholdError('');
										const value = distance.from(e.target.value);
										if (weathermodeSettings == null) { return; }
										if (!(/^[0-9.]+$/).test(e.target.value)) {
											setRainfallThresholdError(t("Please provide a valid number!"));
										}
										else if (value < 2 || value > 20) {
											setRainfallThresholdError(t("Not in the allowable range of 2-20 millimeters"))
										}
										setWeathermodeSettings({ ...weathermodeSettings, rainfall_level_to_warn_mm: value, change_weathermode: true })
									}}
								/>
							</span>
							<span style={{ marginRight: '1rem' }}>{t('Millimeters')}</span>
						</div>
						<p className={css(SettingsPageStyles.error)}>{rainfallThresholdError}</p>
					</div>

					<div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label className={css(SettingsPageStyles.card_input_label)}>{t('Relay Frequency:')}</label>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<span
									className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
									onClick={checkPermission}
								>
									<input
										className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
										type="text"
										style={{ minWidth: '10rem', marginRight: '0.5rem' }}
										defaultValue={toHour(times.weathermode_message_wait_time_s)}
										disabled={!canManageDevices}
										onChange={(e) => {
											setWeathermodeRelayError('');
											if (times == null) { return; }
											if (!(/^[0-9.]+$/).test(e.target.value)) {
												setWeathermodeRelayError(t("Please provide a valid number!"));
											}
											setTimes({ ...times, weathermode_message_wait_time_s: (Number(e.target.value) * 3600), change_time: true });
											setWeathermodeTimesChanged(true)
										}}
									/>
								</span>
								<span style={{ marginRight: '1rem' }}>{t('Hours')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{weathermodeRelayError}</p>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label className={css(SettingsPageStyles.card_input_label)}>{t('Sensor Reading Frequency:')}</label>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<span
									className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
									onClick={checkPermission}
								>
									<input
										className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
										type="text"
										style={{ minWidth: '10rem', marginRight: '0.5rem' }}
										defaultValue={toMinute(times.weathermode_measurement_sleep_time_s)}
										disabled={!canManageDevices}
										onChange={(e) => {
											setWeathermodeReadingError('');
											if (times == null) { return; }
											if (!(/^[0-9.]+$/).test(e.target.value)) {
												setWeathermodeReadingError(t("Please provide a valid number!"));
											}

											setTimes({ ...times, weathermode_measurement_sleep_time_s: (Number(e.target.value) * 60), change_time: true })
											setWeathermodeTimesChanged(true)
										}}
									/>
								</span>
								<span style={{ marginRight: '1rem' }}>{t('Minutes')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{weathermodeReadingError}</p>
						</div>

					</div>
				</section>}

			<p className={css(SettingsPageStyles.error)}>{totalMeasurementsWarning}</p>

			<p className={css(SettingsPageStyles.disclaimer)}>{t('Please note that weather mode settings are applied to all of the devices in the company\'s fleet. These settings are communicated to the devices the next time they transmit data.')}</p>
			<span
				className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
				onClick={checkPermission}
			>
				<Button
					className={css(isSaveButtonEnabled ? button_styles.main_button : button_styles.main_button_disabled)}
					style={{ minWidth: '5rem', padding: '0.2rem 1rem', margin: '1rem 0rem', cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }}
					onClick={toggle}
					disabled={!isSaveButtonEnabled}
				>
					{t('Save')}
				</Button>
			</span>
			<Modal isOpen={modal} toggle={toggle} className={css(SettingsPageStyles.delete_model_style)}>
				<ModalHeader toggle={toggle} style={{ textAlign: 'center' }} >{t('Confirm Settings')}</ModalHeader>
				<ModalBody>
					<Row>
						<legend style={{ fontSize: '1.2em', color: 'red', textAlign: 'center' }}>{t('Are you sure you want to save these settings?')}</legend>
						<Col xs="6" className="d-flex">
							<Button className={css(SettingsPageStyles.cancel_button_style)} onClick={toggle}>
								{t('Cancel')}
							</Button>
						</Col>
						<Col xs="6" className="d-flex">
							<Button className={css(SettingsPageStyles.confirm_delete_button_style)} onClick={() => {
								toggle();
								if (weathermodeSettings == null) { return; }
								company_controller.modify_company_weathermode_settings(weathermodeSettings?.weathermode_on, weathermodeSettings?.rainfall_level_to_warn_mm).then((resp) => {
									company_controller.modify_device_settings(null, null, null, null, times?.weathermode_message_wait_time_s, times?.weathermode_measurement_sleep_time_s);
								})
							}}>
								{t('Save')}
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	</form>

});
