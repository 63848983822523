import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, type SelectChangeEvent, ThemeProvider } from "@mui/material";
import { theme } from "../Theme";
import { addableLabelsSelector } from "../../../models/selectors/device_selectors";
import { useTranslation } from "../../../contexts/TranslationContext";
import { company_controller } from "../../../controllers/company_controller";
import { device_controller } from "../../../controllers/device_controller";
import { deviceInterface } from "../../../models/interfaces/device_interfaces";

const chipStyles = {
    borderRadius: 5,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 500,
    padding: '0.2rem 0',
};

function renderSelected(selected: string[]) {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selected.map(value => (
                <Chip
                    key={value}
                    variant="outlined"
                    style={chipStyles}
                    label={value}
                />
            ))}
        </Box>
    );
}

export function DeviceLabels({ selectedDevice }: { selectedDevice: deviceInterface }) {
    const { t } = useTranslation();
    const addableLabels = useRecoilValue(addableLabelsSelector);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

    function handleChange({ target: { value } }: SelectChangeEvent<string[]>) {
        setSelectedLabels(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    async function saveLabels() {
        const newLabels = selectedLabels.filter(label => !selectedDevice.labels.includes(label));
        const deletedLabels = selectedDevice.labels.filter(
            label => addableLabels.includes(label) && !selectedLabels.includes(label),
        );

        if (!newLabels.length && !deletedLabels.length) {
            return;
        }

        // TODO: refactor backend to accept multiple labels at once
        for (const label of newLabels) {
            await company_controller.add_device_label(selectedDevice.device_id, label);
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        for (const label of deletedLabels) {
            await company_controller.remove_device_label(selectedDevice.device_id, label);
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        await device_controller.get_device_list();
        device_controller.select_device(selectedDevice.device_id);
    }

    useEffect(() => {
        setSelectedLabels(selectedDevice.labels.filter(
            label => addableLabels.includes(label),
        ));
    }, [selectedDevice]);

    return (
        <ThemeProvider theme={theme}>
            <FormControl sx={{ my: 1, width: '100%' }}>
                <InputLabel id="add-label-input-label">{t('Add Label')}</InputLabel>
                <Select
                    labelId="add-label-input-label"
                    multiple
                    value={selectedLabels}
                    onChange={handleChange}
                    input={<OutlinedInput label={t('Add Label')} />}
                    renderValue={renderSelected}
                    onClose={saveLabels}
                    fullWidth
                >
                    {addableLabels.map(label => (
                        <MenuItem
                            key={label}
                            value={label}
                        >
                            <Checkbox
                                checked={selectedLabels.includes(label)}
                                sx={{ py: 0.5 }}
                            />
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
}
