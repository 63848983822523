import { selector } from "recoil";
import { labelsAtom } from "../atoms/company_atoms";
import { systemTypesSelector } from "../atoms/device_atoms";
import { alphanumeric_comparator } from "../../utils/helper_functions";

/**
 * labels, excluding system types, sorted alphabetically
 */
export const labelsSelector = selector({
    key: 'labelsSelector',
    get: ({ get }) => {
        const { labels } = get(labelsAtom);
        const systemTypes = get(systemTypesSelector);
        return labels.filter(label => !systemTypes.includes(label)).sort(alphanumeric_comparator)
    },
});
