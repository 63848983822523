import { selector } from "recoil";
import { selectedDeviceDataAtom } from "../atoms/device_atoms";
import { labelsSelector } from "./company_selectors";

export const maxWaterLevelSelector = selector({
    key: 'maxWaterLevelSelector',
    get: ({ get }) => {
        const selectedDeviceData = get(selectedDeviceDataAtom);
        if (!selectedDeviceData || !selectedDeviceData.water_levels.length) {
            return null;
        }

        const max = Math.max(...selectedDeviceData.water_levels);
        return max === -1 ? null : max;
    },
});

export const maxRainfallLevelSelector = selector({
    key: 'maxRainfallSelector',
    get: ({ get }) => {
        const selectedDeviceData = get(selectedDeviceDataAtom);
        if (!selectedDeviceData || !selectedDeviceData.water_levels.length) {
            return null;
        }

        const max = Math.max(...selectedDeviceData.rainfall_levels);
        return max === -1 ? null : max;
    },
});

const EXCLUDED_LABELS = new Set([
    "Low battery",
    "No messages",
    "High ROC",
    "High Level",
    "blockage",
    "silt",
    "flood",
    "infiltration",
    "In Weather Mode"
]);

/**
 * List of labels that can be added/removed from devices
 */
export const addableLabelsSelector = selector({
    key: 'addableLabelsSelector',
    get: ({ get }) => {
        const labels = get(labelsSelector);
        return labels.filter(label => !EXCLUDED_LABELS.has(label));
    },
})
