import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../../styles/shared_styles.css';
import {
	useRecoilState,
	useRecoilValue,
} from 'recoil';
import { selectedDeviceStateAtom, filterStateAtom, filteredDeviceListSelector, showClientIdentifierSelector } from "../../../models/atoms/device_atoms";
// @ts-ignore
import sort_icon from "../../../assets/sort-icon.svg"
import { deviceListComponentStyles } from '../../styles/device_metrics_page_styles';
import { useState } from "react";
import React from 'react';
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel, Typography } from '@mui/material';
import { primary_background_colour } from '../../styles/style_variables';
import { alphanumeric_comparator } from '../../../utils/helper_functions';
import { DeviceEntry } from './DeviceEntry';
import { DevicesFilter } from './DevicesFilter';

export const ALERT_LABELS = ["Low battery", "No messages", "High ROC", "Blockage", "High Level", "blockage", "flood", "silt", "infiltration"];

export const DeviceListComponent = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t } = useTranslation(); // Use the translation hook
	const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
	const [filter, setFilterState] = useRecoilState(filterStateAtom);
	const filteredDevices = useRecoilValue(filteredDeviceListSelector);
	const [sortDevicesBy, setSortDevicesBy] = useState('device_id');
	const [sortDirection, setSortDirection] = useState<Order>('asc');
	const showClientIdentifier = useRecoilValue(showClientIdentifierSelector);

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		var aVal: any = a[orderBy];
		var bVal: any = b[orderBy];
		if (typeof aVal === 'string' && typeof bVal === 'string') {
			return alphanumeric_comparator(bVal, aVal);
		}

		if (bVal < aVal) {
			return -1;
		}
		if (bVal > aVal) {
			return 1;
		}
		return 0;
	}

	type Order = 'asc' | 'desc';

	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (a: any, b: any) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function updateOrder(orderBy: string) {
		if (sortDevicesBy !== orderBy) {
			setSortDevicesBy(orderBy);
			setSortDirection('asc');
		} else {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		}
	}

	const headers = [
		{ label: 'Device ID', key: 'device_id', sort: true },
		{ label: 'Fill %', key: 'current_level_percentage', sort: true },
		{ label: 'Labels/Alerts', key: 'labels', sort: false },
		{ label: 'Pinned', key: 'pinned', sort: false },
	]

	if (showClientIdentifier) {
		headers.splice(1, 0, { label: 'Client ID', key: 'client_identifier', sort: true });
	}

	console.log('rerender device list');

	return <article data-testid="Device List Component" style={{
		backgroundColor: primary_background_colour,
		borderRadius: 5,
		padding: '1rem 0rem',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		overflow: 'hidden',
	}}>
		<div className={css(deviceListComponentStyles.search_filter_container)}>
			<input
				className={css(deviceListComponentStyles.search)}
				type="text"
				placeholder={t("Search Devices")}
				value={filter.searchText}
				onChange={(event) => setFilterState({ ...filter, searchText: event.target.value })}
			/>
			<div className={css(deviceListComponentStyles.filter_buttons_container)}>
				<DevicesFilter />
			</div>
		</div>

		<TableContainer>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{headers.map((item) => (
							<TableCell align='center' onClick={() => updateOrder(item.key)} sortDirection={sortDevicesBy === item.key ? sortDirection : false}>
								<TableSortLabel
									active={item.sort && sortDevicesBy === item.key}
									hideSortIcon={!item.sort}
									direction={sortDirection}
									onClick={() => updateOrder(item.key)}
									IconComponent={
										() => <img src={sort_icon} alt="sort icon" className={css(deviceListComponentStyles.sort_icon)} />
									}
								>
									<Typography sx={{ fontWeight: 600, fontFamily: '"Ubuntu", sans-serif' }}>{t(item.label)}</Typography>
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredDevices.devices.slice().sort(getComparator(sortDirection, sortDevicesBy)).map((device) => {
						return <DeviceEntry device={device} selectedDevice={selectedDevice} alertLabels={ALERT_LABELS} />
					})}
				</TableBody>
			</Table>
		</TableContainer>
	</article>
};
