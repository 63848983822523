import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { css } from "aphrodite";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { selectedDeviceStateAtom } from "../../../models/atoms/device_atoms";
import { DeviceImageUpload } from "./DeviceImageUpload";

function formatImageURL(image: string) {
    if (!image) {
        return '';
    }

    return `${process.env.REACT_APP_DEVICE_IMAGE_CDN_URL}/${image}`;
}

export function DeviceImages() {
    const { t } = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const selectedDevice = useRecoilValue(selectedDeviceStateAtom);

    useEffect(() => {
        if (selectedDevice === null || !images?.length) {
            return;
        }

        selectImage(0);
    }, [selectedDevice]);

    if (selectedDevice === null) {
        return null;
    }

    const { images, device_id } = selectedDevice;

    function selectImage(index: number) {
        setCurrentImageIndex(index);
    }

    return (
        <>
            <p className={css(deviceInfoPanelStyles.subsection_text)}>
                <span style={{ fontWeight: 500 }}>
                    {t("Installation Photos:")}
                </span>
            </p>
            {images.length
                ? <>
                    <div className={css(deviceInfoPanelStyles.gallery)}>
                        {images.map((image, index) => {
                            const imgURL = formatImageURL(image);
                            return (
                                <img
                                    className="installation-photo"
                                    alt="installation"
                                    key={imgURL}
                                    src={imgURL}
                                    onClick={() => setCurrentImageIndex(index)}
                                />
                            );
                        })}
                    </div>
                    <div>
                        <img
                            alt="selected installation"
                            style={{
                                marginLeft: 50,
                                maxWidth: '70%',
                                marginTop: 10,
                                clear: 'both',
                                borderRadius: 5,
                            }}
                            src={formatImageURL(images[currentImageIndex])}
                        />
                    </div>
                </>
                : <p className={css(deviceInfoPanelStyles.disclaimer)}>
                    {t('(No Photos)')}
                </p>}
            <DeviceImageUpload deviceID={device_id} />
        </>
    );
}
