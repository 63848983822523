import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Layer, Map, MapRef, Source } from 'react-map-gl/mapbox';
import { LngLatBounds, MapMouseEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FeatureCollection } from 'geojson';
import { filteredDeviceListSelector, selectedDeviceStateAtom } from '../../../models/atoms/device_atoms';
import { device_controller } from '../../../controllers/device_controller';
// import LogoPng from "../../../assets/logo.png";

const MAPBOX_TOKEN = "pk.eyJ1IjoibWF4LXdpY2toYW0iLCJhIjoiY2w4bjlnNGIwMGY0NTN1b2FtMDZ4dWRqMSJ9.qbF5dOznUZ0eWlOay_3V4Q";

export function DevicesMap() {
    const filteredDevices = useRecoilValue(filteredDeviceListSelector);
    const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
    const mapRef = useRef<MapRef>(null);

    const devicesDots: FeatureCollection = {
        type: 'FeatureCollection',
        features: filteredDevices.devices.map(device => ({
            type: 'Feature',
            id: device.device_id,
            geometry: {
                type: 'Point',
                coordinates: [device.longitude, device.latitude],
            },
            properties: {
                colour: selectedDevice?.device_id === device.device_id ? "#B42222": "#000099",
                // selected: selectedDevice?.device_id === device.device_id,
            },
        })),
    };

    // function loadImage(e: MapEvent) {
    //     const logoImg = new Image();
    //     logoImg.onload = () => {
    //         e.target.addImage('mhm-logo', logoImg, { sdf: true });
    //     }
    //     logoImg.src = LogoPng;
    // }

    function handleClick(e: MapMouseEvent) {
        // all clicks on map will fire this callback
        // also, console.log(e) doesn's show `features` in it, but console.log(e.features) does...
        if (!e.features?.length) {
            return;
        }

        const clickedDeviceID = e.features[0].id;
        if (!clickedDeviceID) {
            return;
        }
        device_controller.select_device(String(clickedDeviceID));
    }

    useEffect(() => {
        if (!selectedDevice) {
            return;
        }

        mapRef.current?.flyTo({
            center: [selectedDevice.longitude, selectedDevice.latitude],
            zoom: 12,
        });
    }, [selectedDevice]);

    useEffect(() => {
        if (!filteredDevices.devices.length) {
            return;
        }

        const bounds = filteredDevices.devices.reduce(
            (acc, device) => acc.extend([device.longitude, device.latitude]),
            new LngLatBounds(),
        );
        mapRef.current?.fitBounds(
            bounds,
            {
                maxZoom: 12,
                duration: 0,
                padding: { top: 20, right: 50, bottom: 20, left: 50 },
            },
        );
    }, [filteredDevices]);

    return (
        <Map
            ref={mapRef}
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={{
                longitude: -3,
                latitude: 53,
                zoom: 5,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            interactiveLayerIds={['devices']}
            cursor="pointer"
            reuseMaps
            // onLoad={loadImage}
            onClick={handleClick}
        >
            <Source type="geojson" data={devicesDots}>
                <Layer
                    id="devices"
                    type="circle"
                    // filter={['==', 'selected', false]}
                    paint={{
                        'circle-radius': 6,
                        'circle-color': ['get', 'colour'],
                    }}
                />
                {/* <Layer
                    id="selected-device"
                    type="symbol"
                    filter={['==', 'selected', true]}
                    layout={{
                        'icon-image': 'mhm-logo',
                        // icon size & offset set to make centre circle of logo match the position of the dot
                        'icon-size': 0.08,
                        'icon-offset': [0, -27],
                    }}
                    paint={{ 'icon-color': '#000099' }}
                /> */}
            </Source>
        </Map>
    );
}
