import React from 'react';
import { memo, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeCompanyDeviceAtom, changeLowPowerAtom } from "../../../models/atoms/company_atoms";
import { company_controller } from "../../../controllers/company_controller";
import { toHour, toDays } from "../../../utils/helper_functions";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import { useTranslation } from '../../../contexts/TranslationContext';
import { usePermissionsModalContext } from '../../../contexts/PermissionModalContext';
import { canManageDevicesSelector } from '../../../models/selectors/permission_selectors';
import { MAX_MEASUREMENTS } from './DeviceSettings';
/* eslint-disable no-useless-escape */

export const LowPowerSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t } = useTranslation();
	const [times, setTimes] = useRecoilState(changeCompanyDeviceAtom);
	const [lowPowerSettings, setLowPowerSettings] = useRecoilState(changeLowPowerAtom);
	const [lowPowerRelayError, setLowPowerRelayError] = useState('');
	const [lowPowerReadingError, setLowPowerReadingError] = useState('');
	const [batteryThresholdError, setBatteryThresholdError] = useState('');
	const [lowPowerTimesChanged, setLowPowerTimesChanged] = useState(false);
	const [totalMeasurementsWarning, setTotalMeasurementsWarning] = useState('');
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const canManageDevices = useRecoilValue(canManageDevicesSelector);
	const { openPermissionsModal } = usePermissionsModalContext();
	function checkPermission() {
		if (!canManageDevices) {
			openPermissionsModal();
		}
	}

	useEffect(() => {
		if (!times) return;

		const totalLowPowerMeasurements = times.low_power_message_wait_time_s / times.low_power_measurement_sleep_time_s;

		if (totalLowPowerMeasurements > MAX_MEASUREMENTS) {
			setTotalMeasurementsWarning(t('Total measurements exceed 100! Devices are restricted to 100 measurents per transmission interval.', { maxMeasurements: MAX_MEASUREMENTS.toString() }));
		} else {
			setTotalMeasurementsWarning('');
		}
		if (times.low_power_message_wait_time_s < 86400 || times.low_power_message_wait_time_s > 5 * 86400) {
			setLowPowerRelayError(t("Not in the allowable range of 1-5 days"));
		} else {
			setLowPowerRelayError('');
		}
		const maxWaitTime = Math.min(72 * 3600, times.low_power_message_wait_time_s);
		if (times.low_power_measurement_sleep_time_s < 3600 || times.low_power_measurement_sleep_time_s > maxWaitTime) {
			setLowPowerReadingError(t("Not in the allowable range hours low power", { hours: (maxWaitTime / 3600).toFixed(1) }));
		} else {
			setLowPowerReadingError('')
		}
	}, [times, t]);

	if (!times || !lowPowerSettings) {
		return <></>
	}

	const isSaveButtonEnabled = canManageDevices && (lowPowerSettings?.change_low_power || lowPowerTimesChanged) && !lowPowerReadingError && !lowPowerRelayError && !batteryThresholdError && !totalMeasurementsWarning;

	return <form id='low_power_mode' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
		e.preventDefault();
		if (lowPowerSettings == null) { return; }
		company_controller.modify_company_low_power_settings(lowPowerSettings?.low_power_on, lowPowerSettings?.low_power_battery_threshold).then((resp) => {
			company_controller.modify_device_settings(null, null, null, null, null, null, times?.low_power_message_wait_time_s, times?.low_power_measurement_sleep_time_s);
		})
	}}>

		<h2 className={css(SettingsPageStyles.card_title)}>{t("Low Power Mode")}</h2>
		<div className={css(SettingsPageStyles.settings_card_body)}>
			<div style={{ display: "flex", width: '11rem', justifyContent: 'space-between' }}>
				<label className="switch">
					<input
						type="checkbox"
						checked={lowPowerSettings === null ? false : lowPowerSettings.low_power_on}
						disabled={!canManageDevices}
						onChange={async (e) => {
							if (lowPowerSettings === null) { return; }
							setLowPowerSettings({
								...lowPowerSettings,
								low_power_on: e.target.checked,
								change_low_power: true
							});
						}} />
					<span
						className={`slider round ${css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}`}
						onClick={checkPermission}
					/>
				</label>
				<p style={{ fontWeight: 500 }}>{t("Low Power Mode")}</p>
			</div>
			<p className={css(SettingsPageStyles.disclaimer)}>
				{t("Low Power Mode conserves device power until the unit can be replaced, by switching the relay and reading frequencies to be less frequent when the battery percentage of the device falls below the percentage specified below. Each time the device transmits data, the device battery percentage is compared to the defined threshold. Please note that the specified Low Power Mode frequencies must be less frequent than the Normal Operation Mode frequencies. Please note that High Warning Mode overrides Low Power Mode (and Weather Mode if engaged)")}
			</p>
			{lowPowerSettings?.low_power_on && (
				<>
					<section className={css({ ...SettingsPageStyles.settings_card_sections_container, width: '100% !important', marginBottom: 0 })} style={{ width: '100%', marginTop: '2rem' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label className={css(SettingsPageStyles.card_input_label)}>{t("Threshold Battery Percentage")}</label>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<span
									className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
									onClick={checkPermission}
								>
									<input
										className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
										type="text"
										defaultValue={lowPowerSettings?.low_power_battery_threshold}
										disabled={!canManageDevices}
										onChange={(e) => {
											setBatteryThresholdError('');
											if (lowPowerSettings == null) { return; }
											if (!(/^[0-9.]+$/).test(e.target.value)) {
												setBatteryThresholdError(t("Please provide a valid number!"));
											}
											else if (Number(e.target.value) < 10 || Number(e.target.value) > 60) {
												setBatteryThresholdError(t("Not in the allowable range of 10%-60%"));
											}
											setLowPowerSettings({ ...lowPowerSettings, low_power_battery_threshold: Number(e.target.value), change_low_power: true })
										}}
									/>
								</span>
								<span style={{ marginRight: '1rem' }}>%</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{batteryThresholdError}</p>
						</div>

						<div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label className={css(SettingsPageStyles.card_input_label)}>{t("Relay Frequency")}</label>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<span
										className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
										onClick={checkPermission}
									>
										<input
											className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
											type="text"
											style={{ minWidth: '10rem', marginRight: '0.5rem' }}
											defaultValue={toDays(times.low_power_message_wait_time_s)}
											disabled={!canManageDevices}
											onChange={(e) => {
												setLowPowerRelayError('');
												if (times == null) { return; }
												if (!(/^[0-9.]+$/).test(e.target.value)) {
													setLowPowerRelayError(t("Please provide a valid number!"));
												}
												setTimes({ ...times, low_power_message_wait_time_s: (Number(e.target.value) * 24 * 3600), change_time: true });
												setLowPowerTimesChanged(true)
											}}
										/>
									</span>
									<span style={{ marginRight: '1rem' }}>{t("Days")}</span>
								</div>
								<p className={css(SettingsPageStyles.error)}>{lowPowerRelayError}</p>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label className={css(SettingsPageStyles.card_input_label)}>{t("Sensor Reading Frequency")}</label>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<span
										className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
										onClick={checkPermission}
									>
										<input
											className={css(SettingsPageStyles.card_input, !canManageDevices && SettingsPageStyles.disabled_input)}
											type="text"
											style={{ minWidth: '10rem', marginRight: '0.5rem' }}
											defaultValue={toHour(times.low_power_measurement_sleep_time_s)}
											disabled={!canManageDevices}
											onChange={(e) => {
												setLowPowerReadingError('');
												if (times == null) { return; }
												if (!(/^[0-9.]+$/).test(e.target.value)) {
													setLowPowerReadingError(t("Please provide a valid number!"));
												}
												setTimes({ ...times, low_power_measurement_sleep_time_s: (Number(e.target.value) * 60 * 60), change_time: true })
												setLowPowerTimesChanged(true)
											}}
										/>
									</span>
									<span style={{ marginRight: '1rem' }}>{t("Hours")}</span>
								</div>
								<p className={css(SettingsPageStyles.error)}>{lowPowerReadingError}</p>
							</div>
						</div>
					</section>
					<p className={css(SettingsPageStyles.error)}>{totalMeasurementsWarning}</p>
					<p className={css(SettingsPageStyles.disclaimer)}>{t("Please note that device settings are applied to all of the devices in the company's fleet. These settings are communicated to the devices the next time they transmit data.")}</p>
				</>
			)}
			<span
				className={css(!canManageDevices && SettingsPageStyles.disabled_input_wrapper)}
				onClick={checkPermission}
			>
				<Button
					className={css(isSaveButtonEnabled ? button_styles.main_button : button_styles.main_button_disabled)}
					style={{ minWidth: '5rem', padding: '0.2rem 1rem', margin: '1rem 0rem', cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }}
					onClick={toggle}
					disabled={!isSaveButtonEnabled}
				>
					{t("Save")}
				</Button>
			</span>
			<Modal isOpen={modal} toggle={toggle} className={css(SettingsPageStyles.delete_model_style)}>
				<ModalHeader toggle={toggle} style={{ textAlign: 'center' }} >{t("Confirm Settings")}</ModalHeader>
				<ModalBody>
					<Row>
						<legend style={{ fontSize: '1.2em', color: 'red', textAlign: 'center' }}>{t("Are you sure you want to save these settings?")}</legend>
						<Col xs="6" className="d-flex">
							<Button className={css(SettingsPageStyles.cancel_button_style)} onClick={toggle}>
								{t("Cancel")}
							</Button>
						</Col>
						<Col xs="6" className="d-flex">
							<Button className={css(SettingsPageStyles.confirm_delete_button_style)} onClick={() => {
								toggle();
								if (lowPowerSettings == null) { return; }
								company_controller.modify_company_low_power_settings(lowPowerSettings?.low_power_on, lowPowerSettings?.low_power_battery_threshold).then((resp) => {
									company_controller.modify_device_settings(null, null, null, null, null, null, times?.low_power_message_wait_time_s, times?.low_power_measurement_sleep_time_s);
								})
							}}>
								{t("Save")}
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	</form>
});
