import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React, { useEffect, useState } from 'react'
import { MHMTextField } from './TextField'
import { ErrorText, MHMTypography, TitleText } from './Typography'
import { useTranslation } from '../../contexts/TranslationContext'
import { MHMSelect } from './Select'
import { CircularProgress, MenuItem } from '@mui/material'
import { device_controller } from '../../controllers/device_controller'
import { padding } from '@mui/system'

export const BatteryLife: React.FC = () => {
	const { t } = useTranslation();
	const [readingPeriodUnit, setReadingPeriodUnit] = useState(60);
	const [readingPeriod, setReadingPeriod] = useState(15);
	const [readingError, setReadingError] = useState('');
	const [transmissionPeriodUnit, setTransmissionPeriodUnit] = useState(3600);
	const [transmissionPeriod, setTransmissionPeriod] = useState(24);
	const [transmissionError, setTransmissionError] = useState('');
	const [expectedBatteryLife, setExpectedBatteryLife] = useState(10);

	const handleChangeReadingPeriod = (e: any) => {
		console.log(e.target.value);
		if (e.target.value === '' || Number(e.target.value).toString() === 'Infinity') {
			setReadingPeriod(0);
			return;
		}
		if (isNaN(e.target.value)) {
			return;
		}
		setReadingPeriod(Number(e.target.value));
	}

	const handleChangeTransmissionPeriod = (e: any) => {
		if (e.target.value === '' || Number(e.target.value).toString() === 'Infinity') {
			setTransmissionPeriod(0);
			return;
		}
		if (isNaN(e.target.value)) {
			return;
		}
		setTransmissionPeriod(Number(e.target.value));
	}

	useEffect(() => {
		var flag = false;
		if (transmissionPeriod * transmissionPeriodUnit < 720 || transmissionPeriod * transmissionPeriodUnit > 72 * 3600) {
			setTransmissionError(t('Not in the allowable range of 0.2-72 hours'));
			flag = true;
		} else {
			setTransmissionError('');
		}

		const maxReadingFrequency = Math.min(transmissionPeriod * transmissionPeriodUnit, 720 * 60); // Dynamic upper value
		if (readingPeriod * readingPeriodUnit < 300 || readingPeriod * readingPeriodUnit > maxReadingFrequency) {
			if (readingPeriod * readingPeriodUnit > transmissionPeriod * transmissionPeriodUnit) {
				setReadingError(t('Not in the allowable range minutes > transmission', { maxReadingFrequency: (maxReadingFrequency / 60).toFixed(1) }))
			} else {
				setReadingError(t('Not in the allowable range minutes', { maxReadingFrequency: '720.0' }))
			}
			flag = true;
		} else {
			setReadingError('');
		}
		if (flag) {
			return;
		}
		device_controller.get_battery_life(readingPeriod, readingPeriodUnit, transmissionPeriod, transmissionPeriodUnit).then(value => {
			console.log(value);
			if (value !== -1) {
				setExpectedBatteryLife(Number(value));
			}
		}).catch(console.error);
	}, [transmissionPeriod, readingPeriod, transmissionPeriodUnit, readingPeriodUnit]);

	const handleChangeReadingPeriodUnit = (e: any) => {
		setReadingPeriodUnit(e.target.value);
	}

	const handleChangeTransmissionPeriodUnit = (e: any) => {
		setTransmissionPeriodUnit(e.target.value);

	}

	return <Paper sx={{ maxWidth: 800, margin: '1rem', padding: '2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<img style={{ height: 100 }} src={"/static/media/logo-mhm.c4328a679e9c83ba2e75.png"} alt="MHM Logo" />
		</div>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingBottom: '1rem' }}>
			<TitleText variant='h5' sx={{ color: '#000099', paddingBottom: '1rem' }}>
				{t('Battery Life Calculator')}
			</TitleText>
			<MHMTypography variant='body1'>
				{t('Input desired normal operating frequencies for the device to calculate its expected battery life')}
			</MHMTypography>
		</div>
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', paddingBottom: '2rem' }}>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', paddingBottom: '2rem' }}>
				<MHMTypography variant='body1' style={{ fontWeight: 600 }}>
					{t('Take a measurement every')}
				</MHMTypography>
				<div style={{ flex: 1, flexDirection: 'row' }}>
					<MHMTextField id="readingPeriod" style={{ marginRight: '1rem' }} value={readingPeriod} onChange={handleChangeReadingPeriod} />
					<MHMSelect style={{ minWidth: '7rem' }} value={readingPeriodUnit} onChange={handleChangeReadingPeriodUnit}>
						<MenuItem value={86400}>{t('Days')}</MenuItem>
						<MenuItem value={3600}>{t('Hours')}</MenuItem>
						<MenuItem value={60}>{t('Minutes')}</MenuItem>
					</MHMSelect>

				</div>
				<ErrorText variant='body2' style={{ position: 'absolute', bottom: 5, width: 'max-content', maxWidth: 800 }}>
					{t(readingError)}
				</ErrorText>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', paddingBottom: '1rem' }}>
				<MHMTypography variant='body1' style={{ fontWeight: 600 }}>
					{t('Transmit data every')}
				</MHMTypography>
				<div style={{ flex: 1, flexDirection: 'row', }}>
					<MHMTextField id="transmissionPeriod" style={{ marginRight: '1rem' }} value={transmissionPeriod} onChange={handleChangeTransmissionPeriod} />
					<MHMSelect style={{ minWidth: '7rem' }} value={transmissionPeriodUnit} onChange={handleChangeTransmissionPeriodUnit}>
						<MenuItem value={86400}>{t('Days')}</MenuItem>
						<MenuItem value={3600}>{t('Hours')}</MenuItem>
						<MenuItem value={60}>{t('Minutes')}</MenuItem>
					</MHMSelect>
				</div>

			</div>
			<div style={{ position: 'absolute', width: 'max-content', maxWidth: 800, top: '82.5%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				{transmissionError !== '' && <ErrorText variant='body2'>
					{t(transmissionError)}
				</ErrorText>}
			</div>
		</div>

		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '1rem' }}>
			<MHMTypography variant='body1'>
				{t("At this rate of measurements and transmissions, the device battery will last")}
			</MHMTypography>
			<TitleText variant='h4' style={{ paddingTop: '1rem' }}>
				{expectedBatteryLife} {t('years*')}
			</TitleText>
		</div>
		<MHMTypography variant='body2'>
			{t('Disclaimer: this calculator is an estimate only and assumes the device begins with 100% battery, maintains these frequencies its entire lifetime, and operates within the permitted temperature range: -40 to +60°C / -40 to +140°F. Due to the lifetime of other components, the device’s maximum operational life is 10 years.')}
		</MHMTypography>
	</Paper>
}
