import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	typography: {
		fontFamily: 'Ubuntu, sans-serif',
	},
	palette: {
		primary: {
			main: '#000099',
		},
		secondary: {
			main: '#000099',
		},
	},
})
